<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Peralatan Inspeksi'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="outline-dark" @click="$router.push('/peralataninspeksi/add')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                show-empty
                responsive
                hover
                :no-local-sorting="true"
                @sort-changed="sortTable"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                @row-clicked="info"
                :busy="isBusy"
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_peralatan"
                        type="search"
                        placeholder="Cari Nama Peralatan..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-2"
                        size="sm"
                        @input="loadData"
                        v-model="search.manufacture"
                        type="search"
                        placeholder="Cari Manufacture..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-3"
                        size="sm"
                        @input="loadData"
                        v-model="search.model"
                        type="search"
                        placeholder="Cari Model..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-4"
                        size="sm"
                        @input="loadData"
                        v-model="search.kategori"
                        type="search"
                        placeholder="Cari Kategori..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-4"
                        size="sm"
                        @input="loadData"
                        v-model="search.status"
                        type="search"
                        placeholder="Cari Kategori..."
                      ></b-form-input>
                    </b-th>
                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(status)="data">
                  <span v-if="data.item.status == 1" class="text-success">Tersedia</span>
                  <span v-else-if="data.item.status == 2" class="text-info">Dipinjam</span>
                  <span v-else-if="data.item.status == 3" class="text-danger">Rusak</span>
                  <span v-else-if="data.item.status == 4" class="text-warning"
                    >Menunggu Approval</span
                  >
                  <span v-else-if="data.item.status == 5" class="text-warning"
                    >Menunggu Approval Pengembalian</span
                  >
                </template>

                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item
                      @click="pengembalian(data.item, $event.target)"
                      v-if="data.item.status == 2 && !role"
                    >
                      <span class="svg-icon svg-icon-primary"
                        ><svg
                          version="1.1"
                          viewBox="0 0 24 24"
                          height="24px"
                          width="24px"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                          <title xmlns="http://www.w3.org/2000/svg"></title>
                          <desc xmlns="http://www.w3.org/2000/svg"></desc>
                          <defs xmlns="http://www.w3.org/2000/svg"></defs>
                          <g
                            xmlns="http://www.w3.org/2000/svg"
                            id="Stockholm-icons-/-General-/-Attachment1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M12.4644661,14.5355339 L9.46446609,14.5355339 C8.91218134,14.5355339 8.46446609,14.9832492 8.46446609,15.5355339 C8.46446609,16.0878187 8.91218134,16.5355339 9.46446609,16.5355339 L12.4644661,16.5355339 L12.4644661,17.5355339 C12.4644661,18.6401034 11.5690356,19.5355339 10.4644661,19.5355339 L6.46446609,19.5355339 C5.35989659,19.5355339 4.46446609,18.6401034 4.46446609,17.5355339 L4.46446609,13.5355339 C4.46446609,12.4309644 5.35989659,11.5355339 6.46446609,11.5355339 L10.4644661,11.5355339 C11.5690356,11.5355339 12.4644661,12.4309644 12.4644661,13.5355339 L12.4644661,14.5355339 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(8.464466, 15.535534) rotate(-45.000000) translate(-8.464466, -15.535534) "
                            ></path>
                            <path
                              d="M11.5355339,9.46446609 L14.5355339,9.46446609 C15.0878187,9.46446609 15.5355339,9.01675084 15.5355339,8.46446609 C15.5355339,7.91218134 15.0878187,7.46446609 14.5355339,7.46446609 L11.5355339,7.46446609 L11.5355339,6.46446609 C11.5355339,5.35989659 12.4309644,4.46446609 13.5355339,4.46446609 L17.5355339,4.46446609 C18.6401034,4.46446609 19.5355339,5.35989659 19.5355339,6.46446609 L19.5355339,10.4644661 C19.5355339,11.5690356 18.6401034,12.4644661 17.5355339,12.4644661 L13.5355339,12.4644661 C12.4309644,12.4644661 11.5355339,11.5690356 11.5355339,10.4644661 L11.5355339,9.46446609 Z"
                              id="Combined-Shape-Copy"
                              fill="#000000"
                              transform="translate(15.535534, 8.464466) rotate(-45.000000) translate(-15.535534, -8.464466) "
                            ></path>
                          </g>
                        </svg>
                      </span>
                      Pengembalian
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="approval(data.item, $event.target)"
                      v-if="role && (data.item.status == 4 || data.item.status == 5)"
                    >
                      <span class="svg-icon svg-icon-primary"
                        ><svg
                          version="1.1"
                          viewBox="0 0 24 24"
                          height="24px"
                          width="24px"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                          <title xmlns="http://www.w3.org/2000/svg"></title>
                          <desc xmlns="http://www.w3.org/2000/svg"></desc>
                          <defs xmlns="http://www.w3.org/2000/svg"></defs>
                          <g
                            xmlns="http://www.w3.org/2000/svg"
                            id="Stockholm-icons-/-General-/-Attachment1"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M12.4644661,14.5355339 L9.46446609,14.5355339 C8.91218134,14.5355339 8.46446609,14.9832492 8.46446609,15.5355339 C8.46446609,16.0878187 8.91218134,16.5355339 9.46446609,16.5355339 L12.4644661,16.5355339 L12.4644661,17.5355339 C12.4644661,18.6401034 11.5690356,19.5355339 10.4644661,19.5355339 L6.46446609,19.5355339 C5.35989659,19.5355339 4.46446609,18.6401034 4.46446609,17.5355339 L4.46446609,13.5355339 C4.46446609,12.4309644 5.35989659,11.5355339 6.46446609,11.5355339 L10.4644661,11.5355339 C11.5690356,11.5355339 12.4644661,12.4309644 12.4644661,13.5355339 L12.4644661,14.5355339 Z"
                              id="Combined-Shape"
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(8.464466, 15.535534) rotate(-45.000000) translate(-8.464466, -15.535534) "
                            ></path>
                            <path
                              d="M11.5355339,9.46446609 L14.5355339,9.46446609 C15.0878187,9.46446609 15.5355339,9.01675084 15.5355339,8.46446609 C15.5355339,7.91218134 15.0878187,7.46446609 14.5355339,7.46446609 L11.5355339,7.46446609 L11.5355339,6.46446609 C11.5355339,5.35989659 12.4309644,4.46446609 13.5355339,4.46446609 L17.5355339,4.46446609 C18.6401034,4.46446609 19.5355339,5.35989659 19.5355339,6.46446609 L19.5355339,10.4644661 C19.5355339,11.5690356 18.6401034,12.4644661 17.5355339,12.4644661 L13.5355339,12.4644661 C12.4309644,12.4644661 11.5355339,11.5690356 11.5355339,10.4644661 L11.5355339,9.46446609 Z"
                              id="Combined-Shape-Copy"
                              fill="#000000"
                              transform="translate(15.535534, 8.464466) rotate(-45.000000) translate(-15.535534, -8.464466) "
                            ></path>
                          </g>
                        </svg>
                      </span>
                      Approval
                    </b-dropdown-item>
                    <b-dropdown-item @click="info(data.item, $event.target)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="data.item.status == 1"
                      @click="pinjamModal(data.item, $event.target)"
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-dark">
                          <i class="flaticon2-left-arrow-1 text-dark"></i>
                        </span>
                      </div>
                      Pinjam
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="akses == 1"
                      @click="
                        $router.push('/peralataninspeksi/edit/' + data.item.id_peralatan_inspeksi)
                      "
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item>

                    <b-dropdown-item v-if="akses == 1" @click="toDelete(data.item)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>

              <b-modal
                id="modal-pinjam"
                ref="pinjam"
                title="Form Peminjaman"
                size="lg"
                @show="resetModal"
                @ok="handleOk"
              >
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-form-group
                    v-if="!pinjam.pengembalian"
                    label-cols-sm="6"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="6"
                    id="tgl_pinjam_label"
                    label="Tanggal Pinjam :"
                    label-for="tgl_pinjam"
                  >
                    <b-form-input
                      id="tgl_pinjam"
                      v-model="pinjam.tgl_pinjam"
                      type="date"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="!pinjam.pengembalian"
                    label-cols-sm="6"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="6"
                    id="rencana_pengembalian_label"
                    label="Rencana Pengembalian :"
                    label-for="rencana_pengembalian"
                  >
                    <b-form-input
                      id="rencana_pengembalian"
                      v-model="pinjam.rencana_pengembalian"
                      type="date"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="pinjam.pengembalian"
                    label-cols-sm="6"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="6"
                    id="tanggal_pengembalian_label"
                    label="Tanggal Pengembalian :"
                    label-for="tanggal_pengembalian"
                  >
                    <b-form-input
                      id="tanggal_pengembalian"
                      v-model="pinjam.tanggal_pengembalian"
                      type="date"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="pinjam.pengembalian"
                    label-cols-sm="6"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="6"
                    id="durasi_label"
                    label="Durasi (Hari):"
                    label-for="durasi"
                  >
                    <b-form-input
                      id="durasi"
                      v-model="pinjam.durasi"
                      type="number"
                      placeholder="Enter Number..."
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    v-if="pinjam.pengembalian"
                    label-cols-sm="6"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="8"
                    id="penggunaan_label"
                    label="Penggunaan :"
                    label-for="penggunaan"
                  >
                    <vue-editor id="penggunaan" v-model="pinjam.penggunaan"></vue-editor>
                  </b-form-group>
                </form>
              </b-modal>

              <b-modal
                id="info-modal"
                size="xl"
                title="Detail"
                ok-only
                ok-title="Tutup"
                ok-variant="secondary"
                @hide="resetInfoModal"
              >
                <b-row>
                  <b-col>
                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Nama Peralatan :</b></b-col>
                      <b-col>{{ modalInfo.nama_peralatan }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Nomor Inventaris :</b></b-col>
                      <b-col>{{ modalInfo.nomor_inventaris }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Tanggal Pembelian :</b></b-col>
                      <b-col>{{ modalInfo.tanggal_pembelian }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Tahun Dibuat :</b></b-col>
                      <b-col>{{ modalInfo.tahun_dibuat }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Kategori :</b></b-col>
                      <b-col>{{ modalInfo.kategori }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Link :</b></b-col>
                      <b-col>{{ modalInfo.link }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Fungsi :</b></b-col>
                      <b-col v-html="modalInfo.fungsi"></b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Model :</b></b-col>
                      <b-col>{{ modalInfo.model }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Manufacture :</b></b-col>
                      <b-col>{{ modalInfo.manufacture }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Spesifikasi :</b></b-col>
                      <b-col
                        ><div>
                          <b-button v-b-toggle.collapse-1 variant="primary" size="sm"
                            >Lihat</b-button
                          >
                          <b-collapse id="collapse-1" class="mt-2">
                            <b-card>
                              <p v-html="modalInfo.spesifikasi"></p>
                            </b-card>
                          </b-collapse></div
                      ></b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Instruksi Kerja :</b></b-col>
                      <b-col>{{ modalInfo.instruksi_kerja }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Kuantitas Peralatan :</b></b-col>
                      <b-col>{{ modalInfo.kuantity_peralatan }}</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Status Investasi :</b></b-col>
                      <b-col v-if="modalInfo.IsInvestasi">Investasi</b-col>
                      <b-col v-else>Non Investasi</b-col>
                    </b-row>

                    <b-row class="mb-2">
                      <b-col sm="4" class="text-sm-right"><b>Status :</b></b-col>
                      <b-col>
                        <span v-if="modalInfo.status == 1" class="text-success">Tersedia</span>
                        <span v-else-if="modalInfo.status == 2" class="text-info">Dipinjam</span>
                        <span v-else-if="modalInfo.status == 3" class="text-danger">Rusak</span>
                        <span v-else-if="modalInfo.status == 4" class="text-warning"
                          >Menunggu Approval</span
                        >
                        <span v-else-if="modalInfo.status == 5" class="text-warning"
                          >Menunggu Pengambalian</span
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col md="6" offset-md="3">
                    <b-img
                      v-if="modalInfo.foto"
                      :src="urlStorage + '/images/foto_peralatan/' + modalInfo.foto"
                      fluid
                      style="width: 100%"
                    ></b-img>
                    <span v-else>Tidak Ada Foto</span>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-center">
                    <a v-if="akses == 1" @click="toDelete(modalInfo)" class="mx-3">
                      <div class="btn btn-icon btn-clean btn-xl btn-dropdown">
                        <span class="svg-icon svg-icon-xl svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      <span class="text-xl"> Delete </span>
                    </a>

                    <a
                      v-if="akses == 1"
                      @click="
                        $router.push('/peralataninspeksi/edit/' + modalInfo.id_peralatan_inspeksi)
                      "
                      class="mx-3"
                    >
                      <div class="btn btn-icon btn-clean btn-xl btn-dropdown">
                        <span class="svg-icon svg-icon-xl svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </a>
                    <a
                      :href="urlStorage + '/file/manual_book_peralatan/' + modalInfo.manual_book"
                      target="_blank"
                      class="mx-3"
                    >
                      <div class="btn btn-icon btn-clean btn-xl btn-dropdown">
                        <span class="svg-icon svg-icon-xl svg-icon-info">
                          <i class="flaticon2-folder text-info"></i>
                        </span>
                      </div>
                      Manual Book
                    </a>
                    <a v-if="modalInfo.status == 1" @click="pinjamModal(modalInfo, $event.target)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-dark">
                          <i class="flaticon2-left-arrow-1 text-dark"></i>
                        </span>
                      </div>
                      Pinjam
                    </a>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
          <b-modal v-model="modal" okOnly ok-title="Tutup" ok-variant="success">
            <b-row>
              <b-col>
                <span v-html="contentModal"></span>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
    <b-modal
      id="approval-modal"
      size="xl"
      title="Approval"
      ok-only
      ok-title="Tutup"
      ok-variant="secondary"
      ><b-row v-if="items.length">
        <div>
          <h5>Riwayat Peminjaman</h5>
        </div>
        <b-col md="12">
          <b-table
            head-variant="dark"
            bordered
            :items="riwayat.items"
            :fields="riwayat.fields"
            :current-page="riwayat.currentPage"
            stacked="sm"
            show-empty
            responsive
            :busy="isBusy"
          >
            <template #cell(tgl_pinjam)="data">
              <span>{{ moment(data.item.tgl_pinjam) }}</span>
            </template>
            <template #cell(rencana_pengembalian)="data">
              <span>{{ moment(data.item.rencana_pengembalian) }}</span>
            </template>
            <template #cell(tgl_kembali)="data">
              <span v-if="data.item.tgl_kembali">{{ moment(data.item.tgl_pinjam) }}</span>
            </template>

            <template #cell(penggunaan)="data">
              <span v-html="data.item.penggunaan"></span>
            </template>
            <template #cell(actions)="data">
              <div v-if="data.item.tgl_kembali && data.item.finish">
                <h6 class="text-success" v-if="!role">Telah dikembalikan</h6>
                <div v-else>
                  <h6 class="text-success" v-if="data.item.finish && data.item.tgl_kembali">
                    Telah dikembalikan
                  </h6>
                </div>
              </div>
              <h6
                v-else-if="data.item.tgl_kembali == null && form.status == 4 && !role"
                class="text-warning"
              >
                Menuggu Approval
              </h6>
              <div
                v-else-if="
                  (!data.item.finish &&
                    role &&
                    data.item.tgl_kembali == null &&
                    form.status == 4) ||
                    (data.item.tgl_kembali && form.status == 5)
                "
              >
                <div class="btn btn-icon btn-clean btn-lg mr-1">
                  <span
                    class="svg-icon svg-icon-xl svg-icon-success"
                    v-b-tooltip.hover
                    title="Setujui"
                    @click="onSubmitApproval(data.item.id_peminjaman_peralatan_inspeksi, 2)"
                  >
                    <i class="flaticon2-check-mark text-success"></i>
                  </span>
                </div>
                <div class="btn btn-icon btn-clean btn-lg mr-1">
                  <span
                    class="svg-icon svg-icon-xl svg-icon-danger"
                    v-b-tooltip.hover
                    title="Tolak"
                    @click="onSubmitApproval(data.item.id_peminjaman_peralatan_inspeksi, 6)"
                  >
                    <i class="flaticon-close text-dark"></i>
                  </span>
                </div>
              </div>
              <div v-else-if="data.item.tgl_kembali == null && data.item.finish">
                <h6 class="text-danger">Reject</h6>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col md="5">
          <b-pagination
            v-model="currentPage"
            @input="paginate"
            :total-rows="totalRows"
            :per-page="10"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
        <b-col md="5">
          <h4 class="float-right">
            Showing
            <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
            <span v-else>0</span>

            of {{ totalRows }}
          </h4>
        </b-col>
      </b-row></b-modal
    >
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import $ from 'jquery'
import { VueEditor } from 'vue2-editor'
import * as moment from 'moment'
import 'moment/locale/id'

export default {
  data() {
    return {
      role: false,
      form: {
        status: null,
      },
      riwayat: {
        items: [],
        fields: [
          { key: 'name', label: 'Peminjam' },
          { key: 'tgl_pinjam', label: 'Tanggal Pinjam' },
          { key: 'rencana_pengembalian', label: 'Rencana Pengembalian' },
          { key: 'penggunaan', label: 'Penggunaan' },
          { key: 'durasi', label: 'Durasi (Hari)' },
          { key: 'tgl_kembali', label: 'Tanggal Kembali' },
          { key: 'actions', label: 'Actions' },
        ],
        totalRows: 0,
        totalPerPage: 0,
        currentPage: 1,
        selected: 0,
      },
      sortBy: 'nama_peralatan',
      sortDesc: false,
      sort_label: 'ASC',
      items: [],
      fields: [
        { key: 'no', label: 'No' },
        { key: 'nama_peralatan', label: 'Nama Peralatan', sortable: true },
        { key: 'manufacture', label: 'Manufacture', sortable: true },
        { key: 'model', label: 'Model', sortable: true },
        { key: 'kategori', label: 'Kategori', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      search: {
        nama_peralatan: '',
        manufacture: '',
        model: '',
        kategori: '',
        status: '',
      },
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      dialog: false,

      modal: false,
      contentModal: '',

      urlStorage: '',

      modalInfo: {
        id_peralatan_inspeksi: null,
        foto: null,
        nama_peralatan: null,
        model: null,
        fungsi: null,
        tahun_dibuat: null,
        nomor_inventaris: null,
        manual_book: null,
        manufacture: null,
        spesifikasi: null,
        kategori: null,
        tanggal_pembelian: null,
        link: null,
        instruksi_kerja: null,
        kuantity_peralatan: null,
        IsInvestasi: null,
        status: null,
      },

      pinjam: {
        tgl_pinjam: null,
        rencana_pengembalian: null,
        penggunaan: null,
        durasi: null,
        tgl_pinjamState: null,
        penggunaanState: null,
        durasiState: null,
        pengembalian: false,
        tanggal_pengembalian: null,
      },

      id: 0,
      akses: false,
    }
  },
  components: {
    KTCodePreview,
    VueEditor,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Peralatan Inspeksi', route: '' },
      { title: 'Peralatan Inspeksi' },
    ])
  },
  created() {
    var self = this
    self.urlStorage = ApiService.urlStorage()
    ApiService.get(
      '/peralataninspeksi?page=' +
        this.currentPage +
        '&nama_peralatan=' +
        this.search.nama_peralatan +
        '&manufacture=' +
        this.search.manufacture +
        '&model=' +
        this.search.model +
        '&kategori=' +
        this.search.kategori +
        '&status=' +
        this.search.status +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          console.log(data.akses)
          self.akses = data.akses
          self.items = data.data.data
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
          self.role = data.role
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    pengembalian(item, button) {
      this.id = item.id_peralatan_inspeksi
      this.pinjam.pengembalian = true
      this.$root.$emit('bv::show::modal', 'modal-pinjam', button)
    },
    async loadRiwayat(id) {
      var self = this
      self.riwayat.selected = id
      ApiService.get('/peralataninspeksi/edit/' + id + '?page=' + self.riwayat.currentPage)
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.riwayat.items = data.peminjaman.data
            self.riwayat.totalRows = data.peminjaman.total
            self.riwayat.totalPerPage = data.peminjaman.data.length
            self.form.status = data.data.status

            self.role = data.role
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    onSubmitApproval(id, status) {
      const self = this
      self.isBusy = true
      self.loading = true

      var urlApi = ApiService.urlApi()
      const dataku = new FormData()
      dataku.append('status', status)

      $.ajax({
        url: urlApi + '/peralataninspeksi/approval/' + id,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'post',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.loadRiwayat(self.riwayat.selected)
            self.loadData()
            self.isBusy = false
            self.loading = false
            self.formPinjam = false
          }
        },
      })
    },
    async approval(item, button) {
      this.loadRiwayat(item.id_peralatan_inspeksi)
      this.$root.$emit('bv::show::modal', 'approval-modal', button)
    },
    moment: function(date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD').format('dddd, LL')
    },
    info(item, button) {
      this.modalInfo.id_peralatan_inspeksi = item.id_peralatan_inspeksi
      this.modalInfo.foto = item.foto
      this.modalInfo.nama_peralatan = item.nama_peralatan
      this.modalInfo.model = item.model
      this.modalInfo.fungsi = item.fungsi
      this.modalInfo.tahun_dibuat = item.tahun_dibuat
      this.modalInfo.nomor_inventaris = item.nomor_inventaris
      this.modalInfo.manual_book = item.manual_book
      this.modalInfo.manufacture = item.manufacture
      this.modalInfo.spesifikasi = item.spesifikasi
      this.modalInfo.kategori = item.kategori
      this.modalInfo.tanggal_pembelian = item.tanggal_pembelian
      this.modalInfo.link = item.link == 'null' ? '' : item.link
      this.modalInfo.instruksi_kerja = item.instruksi_kerja
      this.modalInfo.kuantity_peralatan = item.kuantity_peralatan
      this.modalInfo.IsInvestasi = item.IsInvestasi
      this.modalInfo.status = item.status
      this.$root.$emit('bv::show::modal', 'info-modal', button)
    },
    pinjamModal(item, button) {
      this.id = item.id_peralatan_inspeksi
      this.$root.$emit('bv::show::modal', 'modal-pinjam', button)
    },
    modalShow(content) {
      this.contentModal = content
      this.modal = true
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get(
        '/peralataninspeksi?page=' +
          this.currentPage +
          '&nama_peralatan=' +
          this.search.nama_peralatan +
          '&manufacture=' +
          this.search.manufacture +
          '&model=' +
          this.search.model +
          '&kategori=' +
          this.search.kategori +
          '&status=' +
          this.search.status +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items = data.data.data
            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.id_peralatan_inspeksi
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/peralataninspeksi/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function() {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.$nextTick(() => {
                      self.$bvModal.hide('info-modal')
                    })
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    resetInfoModal() {
      this.modalInfo.id_peralatan_inspeksi = null
      this.modalInfo.foto = null
      this.modalInfo.nama_peralatan = null
      this.modalInfo.model = null
      this.modalInfo.fungsi = null
      this.modalInfo.tahun_dibuat = null
      this.modalInfo.nomor_inventaris = null
      this.modalInfo.manual_book = null
      this.modalInfo.manufacture = null
      this.modalInfo.spesifikasi = null
      this.modalInfo.kategori = null
      this.modalInfo.tanggal_pembelian = null
      this.modalInfo.link = null
      this.modalInfo.instruksi_kerja = null
      this.modalInfo.kuantity_peralatan = null
      this.modalInfo.IsInvestasi = null
      this.modalInfo.status = null
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.pinjam.tgl_pinjamState = valid
      this.pinjam.penggunaanState = valid
      this.pinjam.durasiState = valid
      return valid
    },
    resetModal() {
      this.pinjam.tgl_pinjam = null
      this.pinjam.penggunaan = null
      this.pinjam.durasi = null
      this.pinjam.tgl_pinjamState = null
      this.pinjam.penggunaanState = null
      this.pinjam.durasiState = null
      this.pinjam.tanggal_pengembalian = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      const self = this
      self.isBusy = true
      self.loading = true
      const tgl_pinjam = self.pinjam.tgl_pinjam
      const penggunaan = self.pinjam.penggunaan
      const durasi = self.pinjam.durasi
      const rencana_pengembalian = self.pinjam.rencana_pengembalian
      const tanggal_pengembalian = self.pinjam.tanggal_pengembalian

      const dataku = new FormData()
      dataku.append('tgl_pinjam', tgl_pinjam)
      dataku.append('penggunaan', penggunaan)
      dataku.append('durasi', durasi)
      dataku.append('tanggal_pengembalian', tanggal_pengembalian)
      dataku.append('rencana_pengembalian', rencana_pengembalian)

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/peralataninspeksi/pinjam/' + self.id,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil dipinjamkan')
            self.loadData()
            self.isBusy = false
            self.loading = false
            self.$nextTick(() => {
              self.$bvModal.hide('modal-pinjam')
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
